
import { defineComponent, reactive } from "vue";
import { authService } from "@/services";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import { getWaring, showError, showSuccess } from "@/libs/notification";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Pagination",
  setup() {
    const router = useRouter();
    const loggedInUser = authService.getUserInfoLocal();
    type ChangePasswordForm = {
      currentPassword?: string;
      password?: string;
      passwordConfirmation?: string;
    };
    const formData = reactive<ChangePasswordForm>({});

    const customSameAs = (value: unknown) => {
      return value === formData.password;
    };

    const rules = {
      currentPassword: {
        required: helpers.withMessage("Mật khẩu cũ bắt buộc nhập", required),
      },
      password: {
        required: helpers.withMessage("Mật khẩu mới bắt buộc nhập", required),
        minLength: helpers.withMessage(
          "Mật khẩu tối thiểu 6 ký tự",
          minLength(6)
        ),
      },
      passwordConfirmation: {
        required: helpers.withMessage(
          "Xác nhận mật khẩu mới bắt buộc nhập",
          required
        ),
        minLength: helpers.withMessage(
          "Mật khẩu tối thiểu 6 ký tự",
          minLength(6)
        ),
        sameAs: helpers.withMessage(
          "Xác nhận mật khẩu không khớp",
          customSameAs
        ),
      },
    };
    const validate = useVuelidate(rules, formData, { $autoDirty: true });

    const save = async () => {
      validate.value.$touch();
      validate.value.$touch();
      if (validate.value.$invalid) {
        showError("Đã xảy ra lỗi");
      } else {
        const hide = getWaring("Đang xử lý");
        hide.showToast();
        try {
          await authService.changePassword(formData);
          hide.hideToast();
          showSuccess(
            "Đổi mật khẩu thành công, vui lòng đăng nhập bằng mật khẩu mới"
          );
          authService.logout();
          await router.push("/");
        } catch (error) {
          if (error?.response?.status === 400) {
            showError("Mật khẩu cũ hoặc mật khẩu mới không chính xác!");
          }
          hide.hideToast();
        }
      }
    };

    return { loggedInUser, validate, save };
  },
});
