<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <div
        class="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse"
      >
        <div class="box mt-5">
          <div class="relative flex items-center p-5">
            <div class="w-12 h-12 image-fit">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full"
                src="@/assets/images/logo.png"
              />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium text-base">
                {{ loggedInUser.fullName }}
              </div>
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <router-link
              class="flex items-center mt-5 text-theme-1 dark:text-theme-10 font-medium"
              :to="{ name: 'change-password' }"
              tag="a"
            >
              <LockIcon class="w-4 h-4 mr-2" /> Đổi mật khẩu
            </router-link>
            <router-link
              class="flex items-center mt-5"
              :to="{ name: 'sender-address' }"
              tag="a"
            >
              <TruckIcon class="w-4 h-4 mr-2" /> Địa chỉ gửi hàng
            </router-link>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <!-- BEGIN: Change Password -->
        <div class="intro-y box lg:mt-5">
          <div
            class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Đổi mật khẩu</h2>
          </div>
          <div class="p-5">
            <div class="relative">
              <label>Mật khẩu cũ</label>
              <input
                type="password"
                class="input w-full border mt-2"
                v-model.trim="validate.currentPassword.$model"
                :class="{
                  'border-theme-6': validate.currentPassword.$error,
                  'border-theme-9':
                    !validate.currentPassword.$error &&
                    validate.currentPassword.$dirty,
                }"
                placeholder="Mật khẩu cũ"
              />
              <div
                class="absolute top-2 right-0 rounded-r w-10 h-full flex items-center justify-center"
              >
                <XCircleIcon
                  v-if="validate.currentPassword.$error"
                  class="w-4 h-4 text-theme-6"
                />
                <CheckCircleIcon
                  v-if="
                    !validate.currentPassword.$error &&
                    validate.currentPassword.$dirty
                  "
                  class="w-4 h-4 text-theme-9"
                />
              </div>
              <template v-if="validate.currentPassword.$error">
                <div class="text-left ml-2 text-theme-6">
                  {{ validate.currentPassword.$errors[0].$message }}
                </div>
              </template>
            </div>
            <div class="relative mt-3">
              <label>Mật khẩu mới</label>
              <input
                type="password"
                class="input w-full border mt-2"
                :class="{
                  'border-theme-6': validate.password.$error,
                  'border-theme-9':
                    !validate.password.$error && validate.password.$dirty,
                }"
                placeholder="Mật khẩu mới"
                v-model.trim="validate.password.$model"
              />
              <div
                class="absolute top-2 right-0 rounded-r w-10 h-full flex items-center justify-center"
              >
                <XCircleIcon
                  v-if="validate.password.$error"
                  class="w-4 h-4 text-theme-6"
                />
                <CheckCircleIcon
                  v-if="!validate.password.$error && validate.password.$dirty"
                  class="w-4 h-4 text-theme-9"
                />
              </div>
              <template v-if="validate.password.$error">
                <div class="text-left ml-2 text-theme-6">
                  {{ validate.password.$errors[0].$message }}
                </div>
              </template>
            </div>
            <div class="relative mt-3">
              <label>Xác nhận mật khẩu mới</label>
              <input
                type="password"
                class="input w-full border mt-2"
                v-model.trim="validate.passwordConfirmation.$model"
                :class="{
                  'border-theme-6': validate.passwordConfirmation.$error,
                  'border-theme-9':
                    !validate.passwordConfirmation.$error &&
                    validate.passwordConfirmation.$dirty,
                }"
                placeholder="Xác nhận mật khẩu mới"
              />
              <div
                class="absolute top-2 right-0 rounded-r w-10 h-full flex items-center justify-center"
              >
                <XCircleIcon
                  v-if="validate.passwordConfirmation.$error"
                  class="w-4 h-4 text-theme-6"
                />
                <CheckCircleIcon
                  v-if="
                    !validate.passwordConfirmation.$error &&
                    validate.passwordConfirmation.$dirty
                  "
                  class="w-4 h-4 text-theme-9"
                />
              </div>
              <template v-if="validate.passwordConfirmation.$error">
                <div class="text-left ml-2 text-theme-6">
                  {{ validate.passwordConfirmation.$errors[0].$message }}
                </div>
              </template>
            </div>
            <button
              type="button"
              class="button bg-theme-1 text-white mt-4"
              @click="save"
            >
              Xác nhận
            </button>
          </div>
        </div>
        <!-- END: Change Password -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { authService } from "@/services";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import { getWaring, showError, showSuccess } from "@/libs/notification";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Pagination",
  setup() {
    const router = useRouter();
    const loggedInUser = authService.getUserInfoLocal();
    type ChangePasswordForm = {
      currentPassword?: string;
      password?: string;
      passwordConfirmation?: string;
    };
    const formData = reactive<ChangePasswordForm>({});

    const customSameAs = (value: unknown) => {
      return value === formData.password;
    };

    const rules = {
      currentPassword: {
        required: helpers.withMessage("Mật khẩu cũ bắt buộc nhập", required),
      },
      password: {
        required: helpers.withMessage("Mật khẩu mới bắt buộc nhập", required),
        minLength: helpers.withMessage(
          "Mật khẩu tối thiểu 6 ký tự",
          minLength(6)
        ),
      },
      passwordConfirmation: {
        required: helpers.withMessage(
          "Xác nhận mật khẩu mới bắt buộc nhập",
          required
        ),
        minLength: helpers.withMessage(
          "Mật khẩu tối thiểu 6 ký tự",
          minLength(6)
        ),
        sameAs: helpers.withMessage(
          "Xác nhận mật khẩu không khớp",
          customSameAs
        ),
      },
    };
    const validate = useVuelidate(rules, formData, { $autoDirty: true });

    const save = async () => {
      validate.value.$touch();
      validate.value.$touch();
      if (validate.value.$invalid) {
        showError("Đã xảy ra lỗi");
      } else {
        const hide = getWaring("Đang xử lý");
        hide.showToast();
        try {
          await authService.changePassword(formData);
          hide.hideToast();
          showSuccess(
            "Đổi mật khẩu thành công, vui lòng đăng nhập bằng mật khẩu mới"
          );
          authService.logout();
          await router.push("/");
        } catch (error) {
          if (error?.response?.status === 400) {
            showError("Mật khẩu cũ hoặc mật khẩu mới không chính xác!");
          }
          hide.hideToast();
        }
      }
    };

    return { loggedInUser, validate, save };
  },
});
</script>
